<script setup></script>

<template>
  <crud-details-page
    api="battle/pet-level"
  >
    <template #form>
      <fields-col
        :sm="6"
      >
        <crud-field-number
          api-field-name="Level"
          required
        />
      </fields-col>
      <fields-col
        :sm="6"
      >
        <crud-field-number api-field-name="RequiredGold" />
        <crud-field-number api-field-name="RequiredCrystals" />
      </fields-col>
      <fields-col
        :sm="6"
      />
    </template>
  </crud-details-page>
</template>
