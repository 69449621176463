import { defineAsyncComponent } from 'vue'
import { CrudRoute, MenuItem } from '!/router'
import { userRightRoles } from '!/composition/utilities'

export default () => {
  /**
   * create routes (with grouping)
   */

  const dashRoutes = [
    CrudRoute.buildRoute('dashboard')
      .setPageComponents(() => import('@/pages/index/IndexPage.vue'))
      .getData()
  ]
  const cacheRoutes = [
    CrudRoute.buildRoute(
      'cache',
      () => import('@/pages/tools/cache/IndexPage.vue'),
      'Cache',
      'Cache maintenance',
      'ic:baseline-cached'
    ).getData()
  ]
  const itemsRoutes = [
    CrudRoute.buildRoute(
      'items-all',
      () => import('@/pages/items/items/IndexPage.vue'),
      'All items',
      'All items',
      'clarity:coin-bag-solid',
      'staticdata.ItemTemplateEntity',
      defineAsyncComponent(() => import('@/components/selectors/ItemsSelector.vue'))
    ).getData(),
    CrudRoute.buildRoute(
      'items-gift-boxes',
      () => import('@/pages/items/items/GiftBoxesPage.vue'),
      'Gift boxes',
      'Gift boxes',
      'ci:dot-03-m',
      'staticdata.ItemTemplateEntity',
      defineAsyncComponent(() => import('@/components/selectors/ItemsSelector.vue'))
    ).getData(),
    CrudRoute.buildRoute(
      'items-guildbanners',
      () => import('@/pages/items/items/GuildBannersPage.vue'),
      'Guild banners',
      'Guild banners',
      'ci:dot-03-m',
      'staticdata.ItemTemplateEntity',
      defineAsyncComponent(() => import('@/components/selectors/ItemsSelector.vue'))
    ).getData(),
    CrudRoute.buildRoute(
      'items-orbs',
      () => import('@/pages/items/items/OrbsPage.vue'),
      'Orbs',
      'Orbs',
      'ci:dot-03-m',
      'staticdata.ItemTemplateEntity',
      defineAsyncComponent(() => import('@/components/selectors/ItemsSelector.vue'))
    ).getData(),
    CrudRoute.buildRoute(
      'items-parts',
      () => import('@/pages/items/items/PartsPage.vue'),
      'Parts',
      'Parts',
      'ci:dot-03-m',
      'staticdata.ItemTemplateEntity',
      defineAsyncComponent(() => import('@/components/selectors/ItemsSelector.vue'))
    ).getData(),
    CrudRoute.buildRoute(
      'items-skins',
      () => import('@/pages/items/items/SkinsPage.vue'),
      'Skins',
      'Skins',
      'ci:dot-03-m',
      'staticdata.ItemTemplateEntity',
      defineAsyncComponent(() => import('@/components/selectors/ItemsSelector.vue'))
    ).getData(),
    CrudRoute.buildRoute(
      'items-talents',
      () => import('@/pages/items/items/TalentsPage.vue'),
      'Talents',
      'Talents',
      'ci:dot-03-m',
      'staticdata.ItemTemplateEntity',
      defineAsyncComponent(() => import('@/components/selectors/ItemsSelector.vue'))
    ).getData(),
    CrudRoute.buildRoute(
      'items-usables',
      () => import('@/pages/items/items/UsablesPage.vue'),
      'Usables',
      'Usables',
      'ci:dot-03-m',
      'staticdata.ItemTemplateEntity',
      defineAsyncComponent(() => import('@/components/selectors/ItemsSelector.vue'))
    ).getData(),
    CrudRoute.buildRoute(
      'items-wearables',
      () => import('@/pages/items/items/WearablesPage.vue'),
      'Wearables',
      'Wearables',
      'ci:dot-03-m',
      'staticdata.ItemTemplateEntity',
      defineAsyncComponent(() => import('@/components/selectors/ItemsSelector.vue'))
    ).getData(),
    CrudRoute.buildRoute(
      'items-pets',
      () => import('@/pages/items/items/PetsPage.vue'),
      'Pets',
      'Pets',
      'ci:dot-03-m',
      'staticdata.ItemTemplateEntity',
      defineAsyncComponent(() => import('@/components/selectors/ItemsSelector.vue'))
    ).getData(),
    CrudRoute.buildRoute('items-part-details', () => import('@/pages/items/items/details/PartDetails.vue'), 'Part item')
      .setIsMenuItem(false)
      .setPath('/items/details/part/:id(\\d+)')
      .setEntity('staticdata.ItemTemplateEntity')
      .setSelector(() => import('@/components/selectors/ItemsSelector.vue'))
      .setParentName('items-parts')
      .getData(),
    CrudRoute.buildRoute('items-giftbox-details', () => import('@/pages/items/items/details/GiftBoxDetails.vue'), 'Gift box item')
      .setIsMenuItem(false)
      .setPath('/items/details/gift-box/:id(\\d+)')
      .setEntity('staticdata.ItemTemplateEntity')
      .setSelector(() => import('@/components/selectors/ItemsSelector.vue'))
      .setParentName('items-gift-boxes')
      .getData(),
    CrudRoute.buildRoute(
      'items-guildbanner-details',
      () => import('@/pages/items/items/details/GuildBannerDetails.vue'),
      'Guild banner item'
    )
      .setIsMenuItem(false)
      .setPath('/items/details/guild-banner/:id(\\d+)')
      .setEntity('staticdata.ItemTemplateEntity')
      .setSelector(() => import('@/components/selectors/ItemsSelector.vue'))
      .setParentName('items-guildbanners')
      .getData(),
    CrudRoute.buildRoute('items-orb-details', () => import('@/pages/items/items/details/OrbDetails.vue'), 'Orb item')
      .setIsMenuItem(false)
      .setPath('/items/details/orb/:id(\\d+)')
      .setEntity('staticdata.ItemTemplateEntity')
      .setSelector(() => import('@/components/selectors/ItemsSelector.vue'))
      .setParentName('items-orbs')
      .getData(),
    CrudRoute.buildRoute('items-talent-details', () => import('@/pages/items/items/details/TalentDetails.vue'), 'Talent item')
      .setIsMenuItem(false)
      .setPath('/items/details/talent/:id(\\d+)')
      .setEntity('staticdata.ItemTemplateEntity')
      .setSelector(() => import('@/components/selectors/ItemsSelector.vue'))
      .setParentName('items-talents')
      .getData(),
    CrudRoute.buildRoute('items-usable-details', () => import('@/pages/items/items/details/UsableDetails.vue'), 'Usable item')
      .setIsMenuItem(false)
      .setPath('/items/details/usable/:id(\\d+)')
      .setEntity('staticdata.ItemTemplateEntity')
      .setSelector(() => import('@/components/selectors/ItemsSelector.vue'))
      .setParentName('items-usables')
      .getData(),
    CrudRoute.buildRoute(
      'items-wearable-details',
      () => import('@/pages/items/items/details/WearableDetails.vue'),
      'Wearable item'
    )
      .setIsMenuItem(false)
      .setPath('/items/details/wearable/:id(\\d+)')
      .setEntity('staticdata.ItemTemplateEntity')
      .setSelector(() => import('@/components/selectors/ItemsSelector.vue'))
      .setParentName('items-wearables')
      .getData(),
    CrudRoute.buildRoute('items-skin-details', () => import('@/pages/items/items/details/SkinDetails.vue'), 'Skin item')
      .setIsMenuItem(false)
      .setPath('/items/details/skin/:id(\\d+)')
      .setEntity('staticdata.ItemTemplateEntity')
      .setSelector(() => import('@/components/selectors/ItemsSelector.vue'))
      .setParentName('items-skins')
      .getData(),
    CrudRoute.buildRoute('items-pet-details', () => import('@/pages/items/items/details/PetDetails.vue'), 'Pet item')
      .setIsMenuItem(false)
      .setPath('/items/details/pet/:id(\\d+)')
      .setEntity('staticdata.ItemTemplateEntity')
      .setSelector(() => import('@/components/selectors/ItemsSelector.vue'))
      .setParentName('items-pets')
      .getData(),
    ...CrudRoute.buildRoutes(
      'wearable-sets',
      () => import('@/pages/items/wearable-sets/IndexPage.vue'),
      () => import('@/pages/items/wearable-sets/DetailsPage.vue'),
      'Wearable sets',
      'Wearable set',
      'bx:closet',
      'staticdata.WearableSetEntity',
      defineAsyncComponent(() => import('@/components/selectors/WearableSetsSelector.vue'))
    ).getData(),
    ...CrudRoute.buildRoutes(
      'myth-orbs-settings',
      () => import('@/pages/items/myth-orbs-settings/IndexPage.vue'),
      () => import('@/pages/items/myth-orbs-settings/DetailsPage.vue'),
      'MythOrb Settings',
      'Mythical Orbs Settings',
      'bx:bowling-ball',
      'staticdata.MythicalOrbSettingsEntity',
      defineAsyncComponent(() => import('@/components/selectors/MythOrbsSettingsSelector.vue'))
    ).getData(),
    ...CrudRoute.buildRoutes(
      'item-tags',
      () => import('@/pages/items/item-tags/IndexPage.vue'),
      () => import('@/pages/items/item-tags/DetailsPage.vue'),
      'Item tags',
      'Item tag',
      'mdi:tag-arrow-right-outline',
      'admin.ItemTagEntity'
    ).getData(),
    ...CrudRoute.buildRoutes(
      'item-tag-groups',
      () => import('@/pages/items/item-tag-groups/IndexPage.vue'),
      () => import('@/pages/items/item-tag-groups/DetailsPage.vue'),
      'Item tag groups',
      'Item tag group',
      'mdi:tag-arrow-right-outline',
      'itemTags.ItemTagGroupEntity',
      defineAsyncComponent(() => import('@/components/selectors/ItemTagGroupsSelector.vue'))
    ).getData()
  ]
  const eventsRoutes = [
    ...CrudRoute.buildRoutes(
      'events',
      () => import('@/pages/events/events/IndexPage.vue'),
      () => import('@/pages/events/events/DetailsPage.vue'),
      'Events',
      'Event',
      'carbon:task-star',
      'staticdata.EventEntity'
    ).getData(),
    ...CrudRoute.buildRoutes(
      'battle-events',
      () => import('@/pages/events/battle-events/IndexPage.vue'),
      () => import('@/pages/events/battle-events/DetailsPage.vue'),
      'Battle events',
      'Battle event',
      'ph:user-list-fill',
      'staticdata.BattleEventEntity',
      defineAsyncComponent(() => import('@/components/selectors/BattleEventsSelector.vue'))
    ).getData(),
    ...CrudRoute.buildRoutes(
      'runic-events',
      () => import('@/pages/events/runic-events/IndexPage.vue'),
      () => import('@/pages/events/runic-events/DetailsPage.vue'),
      'Runic events',
      'Runic event',
      'ph:user-list-fill',
      'staticdata.RunicEventEntity'
    ).getData(),
    ...CrudRoute.buildRoutes(
      'boss-mobs',
      () => import('@/pages/events/boss-mobs/IndexPage.vue'),
      () => import('@/pages/events/boss-mobs/DetailsPage.vue'),
      'BE boss mobs',
      'Battle event boss mob',
      'ph:user-list-fill',
      'staticdata.BattleEventBossMobEntity',
      defineAsyncComponent(() => import('@/components/selectors/BattleEventBossMobsSelector.vue'))
    ).getData(),
    ...CrudRoute.buildRoutes(
      'boss-settings',
      () => import('@/pages/events/boss-settings/IndexPage.vue'),
      () => import('@/pages/events/boss-settings/DetailsPage.vue'),
      'BE boss settings',
      'Battle event boss setting',
      'ph:user-list-fill',
      'staticdata.BattleEventBossSettingsEntity'
    ).getData(),
    ...CrudRoute.buildRoutes(
      'boss-groups',
      () => import('@/pages/events/boss-groups/IndexPage.vue'),
      () => import('@/pages/events/boss-groups/DetailsPage.vue'),
      'BE boss groups',
      'Battle event boss group',
      'ph:user-list-fill',
      'staticdata.BattleEventBossGroupEntity',
      defineAsyncComponent(() => import('@/components/selectors/BossGroupsSelector.vue'))
    ).getData(),
    ...CrudRoute.buildRoutes(
      'slot-multiplies',
      () => import('@/pages/events/slot-multiplies/IndexPage.vue'),
      () => import('@/pages/events/slot-multiplies/DetailsPage.vue'),
      'BE slot multiplies',
      'Battle event slot multiply',
      'ph:user-list-fill',
      'staticdata.BattleEventSlotsMultiplyEntity',
      defineAsyncComponent(() => import('@/components/selectors/BattleEventSlotMultipliesSelector.vue'))
    ).getData(),
    ...CrudRoute.buildRoutes(
      'tier-ranking-rewards',
      () => import('@/pages/events/tier-ranking-rewards/IndexPage.vue'),
      () => import('@/pages/events/tier-ranking-rewards/DetailsPage.vue'),
      'Tier ranking rewards',
      'Tier ranking reward',
      'ph:user-list-fill',
      'staticdata.TierRankingRewardEntity'
    ).getData()
  ]
  const battleRoutes = [
    ...CrudRoute.buildRoutes(
      'battle-level-exp-costs',
      () => import('@/pages/battle/level-exp-costs/IndexPage.vue'),
      () => import('@/pages/battle/level-exp-costs/DetailsPage.vue'),
      'Level exp costs',
      'Level exp cost',
      'fluent:shield-person-add-20-regular',
      'staticdata.LevelCostEntity'
    ).getData(),
    ...CrudRoute.buildRoutes(
      'active-skills',
      () => import('@/pages/battle/active-skills/IndexPage.vue'),
      () => import('@/pages/battle/active-skills/DetailsPage.vue'),
      'Active skills',
      'Active skill',
      'ph:user-list-fill',
      'staticdata.ActiveSkillEntity',
      defineAsyncComponent(() => import('@/components/selectors/BattleActiveSkillSelector.vue'))
    ).getData(),
    ...CrudRoute.buildRoutes(
      'passive-skills',
      () => import('@/pages/battle/passive-skills/IndexPage.vue'),
      () => import('@/pages/battle/passive-skills/DetailsPage.vue'),
      'Passive skills',
      'Passive skill',
      'ph:user-list-fill',
      'staticdata.PassiveSkillEntity',
      defineAsyncComponent(() => import('@/components/selectors/BattlePassiveSkillSelector.vue'))
    ).getData(),
    ...CrudRoute.buildRoutes(
      'battle-locations',
      () => import('@/pages/battle/locations/IndexPage.vue'),
      () => import('@/pages/battle/locations/DetailsPage.vue'),
      'Battle locations',
      'Battle location',
      'fa6-solid:map-location-dot',
      'staticdata.BattleLocationEntity',
      defineAsyncComponent(() => import('@/components/selectors/BattleLocationSelector.vue'))
    )
      .setMenuLabel('Locations')
      .getData(),
    ...CrudRoute.buildRoutes(
      'battle-stages',
      () => import('@/pages/battle/stages/IndexPage.vue'),
      () => import('@/pages/battle/stages/DetailsPage.vue'),
      'Battle stages',
      'Battle stage',
      'la:battle-net',
      'staticdata.BattleStageEntity'
    )
      .setMenuLabel('Stages')
      .getData(),
    ...CrudRoute.buildRoutes(
      'battle-mobs',
      () => import('@/pages/battle/mobs/IndexPage.vue'),
      () => import('@/pages/battle/mobs/DetailsPage.vue'),
      'Battle mobs',
      'Battle mob',
      'mdi:axe-battle',
      'staticdata.BattleMobEntity',
      defineAsyncComponent(() => import('@/components/selectors/BattleMobsSelector.vue'))
    )
      .setMenuLabel('Mobs')
      .getData(),
    ...CrudRoute.buildRoutes(
      'battle-talent-levels',
      () => import('@/pages/battle/talent-levels/IndexPage.vue'),
      () => import('@/pages/battle/talent-levels/DetailsPage.vue'),
      'Talent levels',
      'Talent level',
      'carbon:chart-stepper',
      'staticdata.TalentLevelEntity'
    ).getData(),
    ...CrudRoute.buildRoutes(
      'battle-buff-assets',
      () => import('@/pages/battle/buff-assets/IndexPage.vue'),
      () => import('@/pages/battle/buff-assets/DetailsPage.vue'),
      'Buff assets',
      'Buff asset',
      'emojione-monotone:money-bag',
      'staticdata.BuffAssetsEntity'
    ).getData(),
    ...CrudRoute.buildRoutes(
      'battle-skill-description-tags',
      () => import('@/pages/battle/skill-description-tags/IndexPage.vue'),
      () => import('@/pages/battle/skill-description-tags/DetailsPage.vue'),
      'Skill description tags',
      'Skill description tag',
      'ant-design:tag-filled',
      'staticdata.SkillDescriptionTagsEntity'
    ).getData(),
    CrudRoute.buildRoute(
      'battle-settings-details',
      () => import('@/pages/battle/battle-settings/DetailsPage.vue'),
      'Battle settings'
    )
      .setPath('/battle/battle-settings/details/:id(\\d+)')
      .setForcedId(1)
      .setEntity('staticdata.BattleSettingsEntity')
      .setIcon('material-symbols:settings-suggest-outline')
      .getData(),
    ...CrudRoute.buildRoutes(
      'pet-settings',
      () => import('@/pages/battle/pet-settings/IndexPage.vue'),
      () => import('@/pages/battle/pet-settings/DetailsPage.vue'),
      'Pet settings',
      'Pet setting',
      'streamline:pets-allowed',
      'staticdata.PetEntity',
      defineAsyncComponent(() => import('@/components/selectors/PetSelector.vue'))
    ).getData(),
    ...CrudRoute.buildRoutes(
      'pet-levels',
      () => import('@/pages/battle/pet-levels/IndexPage.vue'),
      () => import('@/pages/battle/pet-levels/DetailsPage.vue'),
      'Pet levels',
      'Pet levels',
      'streamline:pets-allowed',
      'staticdata.PetLevelEntity'
    ).getData()
  ]
  const inventoryRoutes = [
    ...CrudRoute.buildRoutes(
      'loots',
      () => import('@/pages/inventory/loots/IndexPage.vue'),
      () => import('@/pages/inventory/loots/DetailsPage.vue'),
      'Loots',
      'Loot',
      'mdi:gold',
      'staticdata.LootEntity',
      defineAsyncComponent(() => import('@/components/selectors/LootsSelector.vue'))
    ).getData(),
    ...CrudRoute.buildRoutes(
      'chests',
      () => import('@/pages/inventory/chests/IndexPage.vue'),
      () => import('@/pages/inventory/chests/DetailsPage.vue'),
      'Chests',
      'Chest',
      'mdi:treasure-chest',
      'staticdata.ChestEntity',
      defineAsyncComponent(() => import('@/components/selectors/ChestsSelector.vue'))
    ).getData(),
    ...CrudRoute.buildRoutes(
      'challenge-crafts',
      () => import('@/pages/inventory/challenge-crafts/IndexPage.vue'),
      () => import('@/pages/inventory/challenge-crafts/DetailsPage.vue'),
      'Challenge crafts',
      'Challenge craft',
      'file-icons:crafttweaker',
      'staticdata.ChallengeCraftEntity',
      defineAsyncComponent(() => import('@/components/selectors/CraftsSelector.vue'))
    ).getData(),
    ...CrudRoute.buildRoutes(
      'challenge-crafts-tabs',
      () => import('@/pages/inventory/challenge-crafts-tabs/IndexPage.vue'),
      () => import('@/pages/inventory/challenge-crafts-tabs/DetailsPage.vue'),
      'Challenge crafts tabs',
      'Challenge crafts tab',
      'fluent:tabs-20-filled',
      'staticdata.ChallengeCraftTabEntity',
      defineAsyncComponent(() => import('@/components/selectors/CraftTabsSelector.vue'))
    ).getData(),
    ...CrudRoute.buildRoutes(
      'trade-shops',
      () => import('@/pages/inventory/trade-shops/IndexPage.vue'),
      () => import('@/pages/inventory/trade-shops/DetailsPage.vue'),
      'Trade shops',
      'Trade shop',
      'iconoir:shopping-bag',
      'staticdata.TradeShopEntity',
      defineAsyncComponent(() => import('@/components/selectors/TradeShopComponentsSelector.vue'))
    ).getData(),
    ...CrudRoute.buildRoutes(
      'trade-shops-components',
      () => import('@/pages/inventory/trade-shops-components/IndexPage.vue'),
      () => import('@/pages/inventory/trade-shops-components/DetailsPage.vue'),
      'Trade shops components',
      'Trade shops component',
      'iconoir:shopping-bag-add',
      'staticdata.TradeShopComponentEntity',
      defineAsyncComponent(() => import('@/components/selectors/TradeShopComponentsSelector.vue')) // TODO check;
    ).getData(),
    ...CrudRoute.buildRoutes(
      'level-rewards',
      () => import('@/pages/inventory/level-rewards/IndexPage.vue'),
      () => import('@/pages/inventory/level-rewards/DetailsPage.vue'),
      'Level rewards',
      'Level reward',
      'arcticons:rewards',
      'staticdata.LevelRewardEntity'
    ).getData(),
    ...CrudRoute.buildRoutes(
      'daily-bonuses',
      () => import('@/pages/inventory/daily-bonuses/IndexPage.vue'),
      () => import('@/pages/inventory/daily-bonuses/DetailsPage.vue'),
      'Daily bonuses',
      'Daily bonus',
      'ant-design:gift-outlined',
      'staticdata.DailyBonusEntity'
    ).getData(),
    ...CrudRoute.buildRoutes(
      'spins',
      () => import('@/pages/inventory/spins/IndexPage.vue'),
      () => import('@/pages/inventory/spins/DetailsPage.vue'),
      'Spins',
      'Spin',
      'tabler:fidget-spinner',
      'staticdata.SpinEntity',
      defineAsyncComponent(() => import('@/components/selectors/SpinsSelector.vue'))
    ).getData(),
    ...CrudRoute.buildRoutes(
      'talent-loots',
      () => import('@/pages/inventory/talent-loots/IndexPage.vue'),
      () => import('@/pages/inventory/talent-loots/DetailsPage.vue'),
      'Talent loots',
      'Talent loot',
      'fa:diamond',
      'staticdata.TalentsLootEntity',
      defineAsyncComponent(() => import('@/components/selectors/TalentLootSelector.vue'))
    ).getData()
  ]
  const newsRoutes = [
    ...CrudRoute.buildRoutes(
      'news-tops',
      () => import('@/pages/news/tops/IndexPage.vue'),
      () => import('@/pages/news/tops/DetailsPage.vue'),
      'News tops',
      'News top',
      'material-symbols:breaking-news-alt-1-outline',
      'staticdata.NewsTopEntity',
      defineAsyncComponent(() => import('@/components/selectors/NewsTopSelector.vue'))
    ).getData(),
    ...CrudRoute.buildRoutes(
      'news-feeds',
      () => import('@/pages/news/feeds/IndexPage.vue'),
      () => import('@/pages/news/feeds/DetailsPage.vue'),
      'News feeds',
      'News feed',
      'jam:rss-feed',
      'staticdata.NewsFeedEntity'
    ).getData()
  ]
  const cityRoutes = [
    ...CrudRoute.buildRoutes(
      'barber-shops',
      () => import('@/pages/city/barber-shops/IndexPage.vue'),
      () => import('@/pages/city/barber-shops/DetailsPage.vue'),
      'Barber shops',
      'Barber shop',
      'emojione-monotone:barber-pole',
      'staticdata.BarberShopEntity',
      defineAsyncComponent(() => import('@/components/selectors/BarberShopSelector.vue'))
    ).getData(),
    ...CrudRoute.buildRoutes(
      'city-bank-incomes',
      () => import('@/pages/city/bank-incomes/IndexPage.vue'),
      () => import('@/pages/city/bank-incomes/DetailsPage.vue'),
      'Bank incomes',
      'Bank income',
      'clarity:bank-solid',
      'staticdata.BankIncomeEntity'
    ).getData(),
    ...CrudRoute.buildRoutes(
      'city-shop-sets',
      () => import('@/pages/city/shop-sets/IndexPage.vue'),
      () => import('@/pages/city/shop-sets/DetailsPage.vue'),
      'Shop sets',
      'Shop set',
      'carbon:shopping-catalog',
      'staticdata.ShopSetEntity'
    ).getData()
  ]
  const guildRoutes = [
    ...CrudRoute.buildRoutes(
      'guild-academy-bonuses',
      () => import('@/pages/guild/academy-bonuses/IndexPage.vue'),
      () => import('@/pages/guild/academy-bonuses/DetailsPage.vue'),
      'Academy bonuses',
      'Academy bonus',
      'ri:medal-line',
      'staticdata.GuildAcademyBonusEntity'
    ).getData(),
    ...CrudRoute.buildRoutes(
      'guild-fame-perks',
      () => import('@/pages/guild/fame-perks/IndexPage.vue'),
      () => import('@/pages/guild/fame-perks/DetailsPage.vue'),
      'Fame perks',
      'Fame perk',
      'ion:medal-outline',
      'staticdata.GuildFamePerkEntity'
    ).getData(),
    ...CrudRoute.buildRoutes(
      'guild-level-perks',
      () => import('@/pages/guild/level-perks/IndexPage.vue'),
      () => import('@/pages/guild/level-perks/DetailsPage.vue'),
      'Level perks',
      'Level perk',
      'carbon:increase-level',
      'staticdata.GuildLevelPerkEntity'
    ).getData(),
    ...CrudRoute.buildRoutes(
      'guild-rewards',
      () => import('@/pages/guild/guild-rewards/IndexPage.vue'),
      () => import('@/pages/guild/guild-rewards/DetailsPage.vue'),
      'Guild rewards',
      'Guild reward',
      'fluent:reward-12-regular',
      'staticdata.GuildBattleEventRewardEntity'
    )
      .setMenuLabel('Rewards')
      .getData(),
    ...CrudRoute.buildRoutes(
      'guild-settings',
      () => import('@/pages/guild/guild-settings/IndexPage.vue'),
      () => import('@/pages/guild/guild-settings/DetailsPage.vue'),
      'Guild settings',
      'Guild settings',
      'codicon:settings',
      'staticdata.GuildSettingsEntity'
    )
      .setMenuLabel('Settings')
      .getData()
  ]
  const pvpRoutes = [
    ...CrudRoute.buildRoutes(
      'pvp-rewards',
      () => import('@/pages/pvp/pvp-rewards/IndexPage.vue'),
      () => import('@/pages/pvp/pvp-rewards/DetailsPage.vue'),
      'PVP rewards',
      'PVP reward',
      'arcticons:rewards',
      'staticdata.PvpRankingRewardEntity'
    )
      .setMenuLabel('Rewards')
      .getData(),
    ...CrudRoute.buildRoutes(
      'pvp-settings',
      () => import('@/pages/pvp/pvp-settings/IndexPage.vue'),
      () => import('@/pages/pvp/pvp-settings/DetailsPage.vue'),
      'PVP settings',
      'PVP settings',
      'codicon:settings',
      'staticdata.PvpSettingsEntity'
    )
      .setMenuLabel('Settings')
      .getData()
  ]
  const journeysRoutes = [
    ...CrudRoute.buildRoutes(
      'journeys-journeys',
      () => import('@/pages/journeys/journeys/IndexPage.vue'),
      () => import('@/pages/journeys/journeys/DetailsPage.vue'),
      'Journeys',
      'Journey',
      'ic:baseline-travel-explore',
      'staticdata.JourneyEntity'
    ).getData(),
    ...CrudRoute.buildRoutes(
      'journeys-names',
      () => import('@/pages/journeys/names/IndexPage.vue'),
      () => import('@/pages/journeys/names/DetailsPage.vue'),
      'Journey names',
      'Journey name',
      'iconoir:playlist-add',
      'staticdata.JourneyNameEntity'
    )
      .setMenuLabel('Names')
      .getData()
  ]
  const towerRoutes = [
    ...CrudRoute.buildRoutes(
      'tower-stages',
      () => import('@/pages/tower/tower-stages/IndexPage.vue'),
      () => import('@/pages/tower/tower-stages/DetailsPage.vue'),
      'Tower stages',
      'Tower stage',
      'carbon:gradient',
      'staticdata.TowerStageEntity'
    )
      .setMenuLabel('Stages')
      .getData()
  ]
  const paymentsRoutes = [
    ...CrudRoute.buildRoutes(
      'payments-offers',
      () => import('@/pages/payments/offers/IndexPage.vue'),
      () => import('@/pages/payments/offers/DetailsPage.vue'),
      'Payment offers',
      'Payment offer',
      'fluent:receipt-money-20-regular',
      'staticdata.OfferEntity',
      defineAsyncComponent(() => import('@/components/selectors/OffersSelector.vue'))
    )
      .setMenuLabel('Offers')
      .getData(),
    ...CrudRoute.buildRoutes(
      'payments-ars-products',
      () => import('@/pages/payments/ars-products/IndexPage.vue'),
      () => import('@/pages/payments/ars-products/DetailsPage.vue'),
      'Payment ARS products',
      'Payment ARS product',
      'fluent:receipt-money-24-regular',
      'staticdata.ArsOfferEntity'
    )
      .setMenuLabel('ARS products')
      .getData(),
    ...CrudRoute.buildRoutes(
      'special-offers',
      () => import('@/pages/payments/special-offers/IndexPage.vue'),
      () => import('@/pages/payments/special-offers/DetailsPage.vue'),
      'Special offers',
      'Special offer',
      'fluent:receipt-money-24-regular',
      'staticdata.SpecialOffersEntity'
    ).getData(),
    ...CrudRoute.buildRoutes(
      'offers-lists',
      () => import('@/pages/payments/offers-list/IndexPage.vue'),
      () => import('@/pages/payments/offers-list/DetailsPage.vue'),
      'Offers lists',
      'Offers list',
      'fluent:receipt-money-24-regular',
      'staticdata.OffersListEntity'
    ).getData(),
    CrudRoute.buildRoute(
      'payments-weekly-flash-offers-details',
      () => import('@/pages/payments/weekly-flash-offers/DetailsPage.vue'),
      'Weekly flash offer'
    )
      .setPath('/payments/weekly-flash-offers/details/:id(\\d+)')
      .setForcedId(1)
      .setEntity('staticdata.WeeklyFlashOffersEntity')
      .setIcon('carbon:calendar-tools')
      .getData()
  ]
  const bossChallengeRoutes = [
    ...CrudRoute.buildRoutes(
      'bosses-bosses',
      () => import('@/pages/bosses/bosses/IndexPage.vue'),
      () => import('@/pages/bosses/bosses/DetailsPage.vue'),
      'Bosses',
      'Boss',
      'fa6-regular:chess-king',
      'staticdata.BossChallengeMobEntity'
    ).getData(),
    ...CrudRoute.buildRoutes(
      'bosses-rewards',
      () => import('@/pages/bosses/rewards/IndexPage.vue'),
      () => import('@/pages/bosses/rewards/DetailsPage.vue'),
      'Boss rewards',
      'Boss reward',
      'fluent:reward-12-regular',
      'staticdata.ChallengeBossRewardEntity',
      defineAsyncComponent(() => import('@/components/selectors/BossRewardsSelector.vue'))
    )
      .setMenuLabel('Rewards')
      .getData()
  ]
  const questsRoutes = [
    ...CrudRoute.buildRoutes(
      'quests',
      () => import('@/pages/quests/quests/IndexPage.vue'),
      () => import('@/pages/quests/quests/DetailsPage.vue'),
      'Quests',
      'Quest',
      'arcticons:memo-game',
      'staticdata.QuestEntity',
      defineAsyncComponent(() => import('@/components/selectors/QuestsSelector.vue'))
    ).getData(),
    ...CrudRoute.buildRoutes(
      'quests-groups',
      () => import('@/pages/quests/groups/IndexPage.vue'),
      () => import('@/pages/quests/groups/DetailsPage.vue'),
      'Quest groups',
      'Quest group',
      'clarity:blocks-group-solid',
      'staticdata.QuestGroupEntity',
      defineAsyncComponent(() => import('@/components/selectors/QuestGroupsSelector.vue'))
    )
      .setMenuLabel('Groups')
      .getData(),
    ...CrudRoute.buildRoutes(
      'earn-gems',
      () => import('@/pages/quests/earn-gems/IndexPage.vue'),
      () => import('@/pages/quests/earn-gems/DetailsPage.vue'),
      'Earn gems',
      'Earn gem',
      'simple-icons:contactlesspayment',
      'staticdata.EarnGemEntity'
    ).getData(),
    ...CrudRoute.buildRoutes(
      'event-ranking-rewards',
      () => import('@/pages/quests/event-ranking-rewards/IndexPage.vue'),
      () => import('@/pages/quests/event-ranking-rewards/DetailsPage.vue'),
      'Event ranking rewards',
      'Event ranking reward',
      'arcticons:rewards',
      'staticdata.QuestEventRankingRewardEntity',
      defineAsyncComponent(() => import('@/components/selectors/QuestEventRankingRewardsSelector.vue'))
    ).getData(),
    ...CrudRoute.buildRoutes(
      'npcs',
      () => import('@/pages/quests/npcs/IndexPage.vue'),
      () => import('@/pages/quests/npcs/DetailsPage.vue'),
      'NPCs',
      'NPC',
      'ic:outline-spatial-tracking',
      'staticdata.NpcEntity',
      defineAsyncComponent(() => import('@/components/selectors/NpcsSelector.vue'))
    ).getData(),
    ...CrudRoute.buildRoutes(
      'dialogs',
      () => import('@/pages/quests/dialogs/IndexPage.vue'),
      () => import('@/pages/quests/dialogs/DetailsPage.vue'),
      'Dialogs',
      'Dialog',
      'fluent:people-list-16-regular',
      'staticdata.DialogEntity',
      defineAsyncComponent(() => import('@/components/selectors/DialogsSelector.vue'))
    ).getData(),
    ...CrudRoute.buildRoutes(
      'milestones',
      () => import('@/pages/quests/milestones/IndexPage.vue'),
      () => import('@/pages/quests/milestones/DetailsPage.vue'),
      'Milestones',
      'Milestone',
      'codicon:milestone',
      'staticdata.MilestoneEntity',
      defineAsyncComponent(() => import('@/components/selectors/MilestonesSelector.vue'))
    ).getData(),
    ...CrudRoute.buildRoutes(
      'milestone-daily-milestone-exp',
      () => import('@/pages/quests/daily-milestone-exp/IndexPage.vue'),
      () => import('@/pages/quests/daily-milestone-exp/DetailsPage.vue'),
      'Daily milestone exp',
      'Daily milestone exp',
      'carbon:settings-adjust',
      'staticdata.DailyQuestMilestoneExpEntity'
    ).getData(),
    ...CrudRoute.buildRoutes(
      'criteria-settings',
      () => import('@/pages/quests/criteria-settings/IndexPage.vue'),
      () => import('@/pages/quests/criteria-settings/DetailsPage.vue'),
      'Criteria settings',
      'Criteria setting',
      'codicon:settings',
      'staticdata.CriteriaSettingEntity'
    ).getData()
  ]
  const extrasRoutes = [
    ...CrudRoute.buildRoutes(
      'extras-frames',
      () => import('@/pages/extras/frames/IndexPage.vue'),
      () => import('@/pages/extras/frames/DetailsPage.vue'),
      'Frames',
      'Frame',
      'simple-line-icons:frame',
      'staticdata.FrameEntity'
    ).getData()
  ]
  const settingsRoutes = [
    ...CrudRoute.buildRoutes(
      'client-hero-flags',
      () => import('@/pages/settings/client-hero-flags/IndexPage.vue'),
      () => import('@/pages/settings/client-hero-flags/DetailsPage.vue'),
      'Client hero flags',
      'Client hero flag',
      'dashicons:superhero',
      'staticdata.ClientHeroFlagEntity'
    ).getData(),
    ...CrudRoute.buildRoutes(
      'vip-statuses',
      () => import('@/pages/settings/vip-statuses/IndexPage.vue'),
      () => import('@/pages/settings/vip-statuses/DetailsPage.vue'),
      'VIP statuses',
      'VIP status',
      'ri:vip-line',
      'staticdata.VipStatusEntity'
    ).getData(),
    ...CrudRoute.buildRoutes(
      'question-marks',
      () => import('@/pages/settings/question-marks/IndexPage.vue'),
      () => import('@/pages/settings/question-marks/DetailsPage.vue'),
      'Question marks',
      'Question mark',
      'mdi:map-marker-question-outline',
      'staticdata.QuestionMarkEntity'
    ).getData(),
    ...CrudRoute.buildRoutes(
      'secret-code-rewards-by-me',
      () => import('@/pages/settings/secret-code-rewards-by-me/IndexPage.vue'),
      () => import('@/pages/settings/secret-code-rewards-by-me/DetailsPage.vue'),
      'Secret code rewards me',
      'Secret code reward me',
      'arcticons:secretcodes',
      'staticdata.SecretCodeRewardByMeEntity'
    ).getData(),
    ...CrudRoute.buildRoutes(
      'secret-code-rewards-by-others',
      () => import('@/pages/settings/secret-code-rewards-by-others/IndexPage.vue'),
      () => import('@/pages/settings/secret-code-rewards-by-others/DetailsPage.vue'),
      'Secret code rewards others',
      'Secret code reward others',
      'carbon:code-signing-service',
      'staticdata.SecretCodeRewardByOthersEntity'
    ).getData(),
    ...CrudRoute.buildRoutes(
      'promo-codes',
      () => import('@/pages/settings/promo-codes/IndexPage.vue'),
      () => import('@/pages/settings/promo-codes/DetailsPage.vue'),
      'Promo codes',
      'Promo code',
      'clarity:qr-code-line',
      'staticdata.PromoCodeEntity'
    ).getData(),
    ...CrudRoute.buildRoutes(
      'perk-descriptions',
      () => import('@/pages/settings/perk-descriptions/IndexPage.vue'),
      () => import('@/pages/settings/perk-descriptions/DetailsPage.vue'),
      'Perk descriptions',
      'Perk description',
      'mi:circle-information',
      'staticdata.PerkDescriptionEntity'
    ).getData(),
    ...CrudRoute.buildRoutes(
      'initial-inventory',
      () => import('@/pages/settings/initial-inventory/IndexPage.vue'),
      () => import('@/pages/settings/initial-inventory/DetailsPage.vue'),
      'Initial inventory',
      'Initial inventory',
      'el:list-alt',
      'staticdata.InitialInventoryEntity'
    ).getData(),
    ...CrudRoute.buildRoutes(
      'feature-unlocks',
      () => import('@/pages/settings/feature-unlocks/IndexPage.vue'),
      () => import('@/pages/settings/feature-unlocks/DetailsPage.vue'),
      'Feature unlocks',
      'Feature unlocks',
      'ant-design:unlock-outlined',
      'staticdata.FeatureUnlockEntity'
    ).getData(),
    ...CrudRoute.buildRoutes(
      'game-settings',
      () => import('@/pages/settings/game-settings/IndexPage.vue'),
      () => import('@/pages/settings/game-settings/DetailsPage.vue'),
      'Game settings',
      'Game setting',
      'carbon:calendar-tools',
      'staticdata.GameSettingsEntity'
    ).getData()
  ]
  const faqRoutes = [
    ...CrudRoute.buildRoutes(
      'faq-tabs',
      () => import('@/pages/faq/tabs/IndexPage.vue'),
      () => import('@/pages/faq/tabs/DetailsPage.vue'),
      'Faq tabs',
      'Faq tab',
      'ph:tabs-light',
      'staticdata.FaqTabEntity',
      defineAsyncComponent(() => import('@/components/selectors/FaqTabsSelector.vue'))
    )
      .setMenuLabel('Tabs')
      .getData(),
    ...CrudRoute.buildRoutes(
      'faq-items',
      () => import('@/pages/faq/items/IndexPage.vue'),
      () => import('@/pages/faq/items/DetailsPage.vue'),
      'Faq items',
      'Faq item',
      'codicon:list-unordered',
      'staticdata.FaqItemEntity'
    )
      .setMenuLabel('Items')
      .getData()
  ]
  const toolsRoutes = [
    ...CrudRoute.buildRoutes(
      'global-messages',
      () => import('@/pages/tools/global-messages/IndexPage.vue'),
      () => import('@/pages/tools/global-messages/DetailsPage.vue'),
      'Global messages',
      'Global message',
      'ant-design:info-circle-outlined',
      'admin.APGlobalMessageEntity'
    ).getData(),
    ...CrudRoute.buildRoutes(
      'personal-messages',
      () => import('@/pages/tools/personal-messages/IndexPage.vue'),
      () => import('@/pages/tools/personal-messages/DetailsPage.vue'),
      'Personal messages',
      'Personal message',
      'fluent:person-info-20-regular',
      'admin.PersonalMessageEntity'
    ).getData(),
    ...CrudRoute.buildRoutes(
      'annotations',
      () => import('@/pages/tools/annotations/IndexPage.vue'),
      () => import('@/pages/tools/annotations/DetailsPage.vue'),
      'Changelogs',
      'Changelog',
      'material-symbols:mode-comment-outline',
      'admin.AnnotationEntity'
    ).getData(),
    ...CrudRoute.buildRoutes(
      'tools-global-push-notifications',
      () => import('@/pages/tools/global-push-notifications/IndexPage.vue'),
      () => import('@/pages/tools/global-push-notifications/DetailsPage.vue'),
      'Global push notifications',
      'Global push notification',
      'material-symbols:edit-notifications-outline',
      'gameplayCommon.GlobalPushNotificationEntity'
    ).getData(),
    ...CrudRoute.buildRoutes(
      'tools-players',
      () => import('@/pages/tools/players/IndexPage.vue'),
      () => import('@/pages/tools/players/DetailsPage.vue'),
      'Players',
      'Player',
      'mdi:person-multiple',
      'gameplay.PlayerEntity'
    )
      .setAccessRole(userRightRoles.playerViewer)
      .getData(),
    ...CrudRoute.buildRoutes(
      'tools-guilds',
      () => import('@/pages/tools/guilds/IndexPage.vue'),
      () => import('@/pages/tools/guilds/DetailsPage.vue'),
      'Guilds',
      'Guild',
      'arcticons:guilded',
      'gameplay.GuildEntity'
    )
      .setAccessRole(userRightRoles.playerViewer)
      .getData(),
    ...CrudRoute.buildRoutes(
      'console-commands',
      () => import('@/pages/tools/console-commands/IndexPage.vue'),
      () => import('@/pages/tools/console-commands/DetailsPage.vue'),
      'Console Commands',
      'Console Command',
      'material-symbols:mail-outline',
      'admin.ConsoleCommandEntity'
    ).getData(),
    CrudRoute.buildRoute(
      'tools-create-players',
      () => import('@/pages/tools/create-players/IndexPage.vue'),
      'Create multiple players',
      'Create multiple players',
      'mdi:person-multiple'
    )
      .setAccessRole(userRightRoles.clientConsoleProd)
      .getData()
  ]
  const translationsRoutes = [
    ...CrudRoute.buildRoutes(
      'translations-message-strings',
      () => import('@/pages/translations/message-strings/IndexPage.vue'),
      () => import('@/pages/translations/message-strings/DetailsPage.vue'),
      'Message strings',
      'Message string',
      'ep:message-box',
      'staticdata.MessageStringEntity'
    ).getData(),
    ...CrudRoute.buildRoutes(
      'translations-quest-criteria-strings',
      () => import('@/pages/translations/quest-criteria-strings/IndexPage.vue'),
      () => import('@/pages/translations/quest-criteria-strings/DetailsPage.vue'),
      'Quest criteria strings',
      'Quest criteria string',
      'arcticons:memo-game',
      'staticdata.QuestCriteriaStringEntity'
    ).getData(),
    ...CrudRoute.buildRoutes(
      'translations-template-strings',
      () => import('@/pages/translations/template-strings/IndexPage.vue'),
      () => import('@/pages/translations/template-strings/DetailsPage.vue'),
      'Template strings',
      'Template string',
      'gg:template',
      'staticdata.StringTemplateEntity'
    ).getData()
  ]

  const creatorRoutes = [
    ...CrudRoute.buildRoutes(
      'creator-creator',
      () => import('@/pages/creator/creator/IndexPage.vue'),
      () => import('@/pages/creator/creator/DetailsPage.vue'),
      'Creator',
      'Creator',
      'ep:message-box',
      'staticdata.CreatorEntity'
    ).getData()
  ]

  return [
    MenuItem.build('dashboard', dashRoutes).getData(),
    MenuItem.build('cache-btn')
      .setMenuItemComponent(defineAsyncComponent(() => import('@/pages/tools/cache/ItemMenu.vue')))
      .getData(),
    MenuItem.build('cache', cacheRoutes).setIsSubMenu(false).getData(),
    MenuItem.build('items', itemsRoutes, 'ic:baseline-dialpad', 'Items').setCssClass('opacity-80').getData(),
    MenuItem.build('events', eventsRoutes, 'fluent:task-list-add-24-regular', 'Events').getData(),
    MenuItem.build('battle', battleRoutes, 'mdi:fencing', 'Battle').getData(),
    MenuItem.build('inventory', inventoryRoutes, 'el:list-alt', 'Inventory').setCssClass('opacity-80').getData(),
    MenuItem.build('news', newsRoutes, 'arcticons:google-newsstand', 'News').setCssClass('stroke-2').getData(),
    MenuItem.build('city', cityRoutes, 'healthicons:city-outline', 'City').getData(),
    MenuItem.build('guild', guildRoutes, 'arcticons:guilded', 'Guild').setCssClass('stroke-2').getData(),
    MenuItem.build('pvp', pvpRoutes, 'foundation:thumbnails', 'Pvp').setCssClass('opacity-80').getData(),
    MenuItem.build('journeys', journeysRoutes, 'icon-park:journey', 'Journeys').setCssClass('gs-light-menu-icon').getData(),
    MenuItem.build('tower', towerRoutes, 'icon-park:eiffel-tower', 'Tower').setCssClass('gs-light-menu-icon').getData(),
    MenuItem.build('payments', paymentsRoutes, 'la:money-bill-wave-alt', 'Payments').setCssClass('opacity-80').getData(),
    MenuItem.build('bosses', bossChallengeRoutes, 'icon-park-outline:crown-three', 'Boss challenge')
      .setCssClass('gs-light-menu-icon')
      .getData(),
    MenuItem.build('quests', questsRoutes, 'emojione-monotone:game-die', 'Quests').getData(),
    MenuItem.build('extras', extrasRoutes, 'icon-park-outline:game-ps', 'Extras').setCssClass('gs-light-menu-icon-x2').getData(),
    MenuItem.build('faq', faqRoutes, 'ph:circle-wavy-question', 'Faq').setCssClass('opacity-80').getData(),
    MenuItem.build('translations', translationsRoutes).getData(),
    MenuItem.build('settings', settingsRoutes).getData(),
    MenuItem.build('tools', toolsRoutes).getData(),
    MenuItem.build('creator', creatorRoutes, 'arcticons:guilded', 'Creator').setCssClass('stroke-2').getData()
  ]
}
