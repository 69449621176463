<template>
  <crud-details-page
    api="settings/question-mark"
  >
    <template #form>
      <fields-col :sm="8">
        <!-- Place -->
        <crud-field-select
          api-field-name="Place"
          options-enum="enums.QuestionMarkPlace"
          required
        />
        <!-- Description -->
        <crud-field-selector-client-strings
          api-field-name="Description"
          required
        />
      </fields-col>
      <fields-col :sm="8">
        <crud-field-selector-files
          v-for="i in 5"
          :key="i"
          :api-field-name="`InsideGraphic${i}`"
        />
      </fields-col>
      <fields-col :sm="8">
        <crud-field-selector-files
          v-for="i in 5"
          :key="i"
          :api-field-name="`InsideGraphic${i + 5}`"
        />
      </fields-col>
    </template>
  </crud-details-page>
</template>
